'use strict';

angular.module('cpformplastApp.items')
  .config(function ($stateProvider) {
    $stateProvider
      .state('items/category-management', {
        url: '/items/category-management',
        templateUrl: 'app/items/category-management/category-management.html',
        controller: 'categoryManagementController',
        authenticate: ['admin', "administration_dept",'management_dept','receptionist']
      })
      .state('items/category-management/category-creation', {
        url: '/items/category-management/category-creation/:categoryId',
        templateUrl: 'app/items/category-management/category-creation/category-creation.html',
        controller: 'categoryCreationController',
        authenticate: ['admin', "administration_dept",'management_dept','receptionist']
      })
      .state('items/item-management', {
        url: '/items/item-management',
        templateUrl: 'app/items/item-management/item-management.html',
        controller: 'itemManagementController',
        authenticate: ['admin', 'administration_dept','management_dept','salesmen_dept','mold_dept','receptionist', "quality_dept"]
      })
      .state('items/item-management/item-creation', {
        url: '/items/item-management/item-creation/:itemId',
        templateUrl: 'app/items/item-management/item-creation/item-creation.html',
        controller: 'itemCreationController',
        authenticate: ['admin','administration_dept','management_dept','salesmen_dept','mold_dept','receptionist', "quality_dept"]
      })
      .state('items/item-planning', {
        url: '/items/item-planning',
        templateUrl: 'app/items/item-planning/item-planning.html',
        controller: 'itemPlanningController',
        authenticate: ['admin','administration_dept','management_dept', 'mold_dept','receptionist']
      });
  });
